/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react'
import { AuthEmailType, IQuery, IUser, Maybe } from '../../types/graphql'

export type IAuthContext = {
  user: any | null | undefined
  currentUser: Maybe<IUser>
  isAuthenticated: boolean
  loading: boolean
  loadingCurrentUser: boolean
  toggleLoginModal: (open?: boolean) => void
  logout: () => Promise<void>
  refetchCurrentUser: () => Promise<void>
  updateCurrentUser: (
    updateData: (
      data: Pick<IQuery, 'currentUser'>
    ) => Pick<IQuery, 'currentUser'>
  ) => void
  sendAuthEmail: (
    email: string,
    emailType: AuthEmailType,
    redirectUrl?: string
  ) => Promise<any>
  referrer: string | undefined
  setReferrer: (referrer: string) => void
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  currentUser: null,
  isAuthenticated: false,
  loading: false,
  loadingCurrentUser: false,
  toggleLoginModal: () => {},
  logout: async () => {},
  refetchCurrentUser: async () => {},
  updateCurrentUser: () => {},
  sendAuthEmail: async () => {},
  referrer: undefined,
  setReferrer: () => {}
})

export const useAuthProvider = (): IAuthContext => useContext(AuthContext)
